import JobsPage from "../pages/jobs";
import { graphql } from "gatsby";

export default JobsPage;

export const pageQuery = graphql`
query TemplateJobsPage($skip: Int) {
  jobs: allMarkdownRemark(
    filter: {
      frontmatter: { draft: { ne: true } },
      fileAbsolutePath: {regex: "/\/jobs\//"},
      excerpt: {}
    }
    sort:{ order:DESC, fields:[frontmatter___rank]}
    limit: 10
    skip: $skip
  ) {
    totalCount
    edges {
      node {
        frontmatter {
          organization
          jobTitle
          location
          url
          tags
          image {
            children {
              ... on ImageSharp {
                fixed(width: 120) {
                	src
            			srcSet
                }
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`;
